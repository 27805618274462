<template>

  <Pantalla1 />


  </template>

<script>

  import Pantalla1 from '../components/Pantalla-1'


  export default {
    name: 'Pantalla-1',

    components: {
      Pantalla1,
  
    },
  }
</script>
