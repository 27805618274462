<template>
  <v-container>
    <v-card>
      <v-toolbar
        dense
        flat
        class="body-2 font-weight-bold"
        color="grey lighten-2"
      >
        Mensaje del sistema
      </v-toolbar>

      <v-row class="ma-1">
        <v-col cols="12"> Registro creado correctamente </v-col>
        <v-col cols="12" md="6" class="text-center">
          <v-btn class="ma-2" color="info" @click="nuevo_registro()">
            Nuevo Registro
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon light>mdi-cached</v-icon>
              </span>
            </template>
          </v-btn>

          <v-btn class="ma-2" color="info" @click="salir()">
            Cerrar
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon light>mdi-cached</v-icon>
              </span>
            </template>
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "Resultado",
  created: function () {
    history.pushState(null, null, "correcto");
    window.addEventListener("popstate", function () {
      history.pushState(null, null, "correcto");
    });
  },

  data() {
    return {
      loader: null,
      loading: false,
    };
  },
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 3000);

      this.loader = null;
      //this.$router.push({ path: '/pantalla1' });
      location.href = "/pantalla1";
    },
  },
  methods: {
    nuevo_registro() {
      location.href = "/pantalla1";
      //this.$router.push({ path: '/pantalla1' });
    },
    salir() {
      localStorage.setItem("usuario", "");
      localStorage.setItem("nombre", "");
      localStorage.setItem("apellido", "");
      localStorage.setItem("empresa", "");
      location.href = "/";
      window.close();
      //this.$router.push({ path: '/pantalla1' });
    },
  },
};
</script>

<style>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-o-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
