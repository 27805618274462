<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">


    

  
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-select
            v-model="lugares"
            :items="items"
            item-text="nombre"
            item-value="id"
            prepend-icon="mdi-map-marker"
            label="Seleccionar Ubicación"
            solo
            @change="chequeaUbicacion($event)"
          ></v-select>

          <div
            style="padding-bottom: 20px; font-size: 12px; font-weight: bold"
            v-show="isUbicacion"
          >
            <v-icon style="display: flex !important" color="blue darken-2">
              mdi-domain-off
            </v-icon>
            Objetivo seleccionado fuera de la ubicación
          </div>

          <v-text-field
            prepend-icon="mdi-calendar"
            v-model="fecha"
            label="Fecha"
            readonly
          ></v-text-field>

              <v-card v-show="isConsignas" class="mx-auto my-12" max-width="374">              
                <v-card-title><v-icon>mdi-alert</v-icon>Consignas</v-card-title>              
                <v-card-text v-for="consigna in consignas" :key="consigna.id">
                  <div><b>{{consigna.titulo}}</b></div>
                  <v-divider class="mx-4"></v-divider>
                  <div>{{consigna.descripcion}}</div>
                  <v-divider class="mx-4"></v-divider>
                </v-card-text>              
                           
                
              </v-card>

          <v-text-field
            background-color="rgba(0, 123, 255, 0.05)"
            prepend-icon="mdi-format-text"
            v-model="titulo_minuta1"
            :rules="tituloRules"
            required
            outlined
            label="Título: ingrese un título descriptivo"
          >
          </v-text-field>
          <v-textarea
            background-color="rgba(0, 123, 255, 0.05)"
            prepend-icon="mdi-clipboard-text-outline"
            v-model="minuta1"
            :rules="descripcionRules"
            required
            label="Descripción: ingrese una descripción de la situación a reportar"
            outlined
          >
          </v-textarea>

          <v-combobox
            prepend-icon="mdi-lan"
            v-model="select1"
            :items="areas"
            label="Seleccionar area a avisar"
            multiple
            chips
            outlined
          >
            <template v-slot:selection="data">
              <v-chip
                :key="JSON.stringify(data.item)"
                v-bind="data.attrs"
                :input-value="data.selected"
                :disabled="data.disabled"
                @click:close="data.parent.selectItem(data.item)"
              >
                <v-avatar
                  class="accent white--text"
                  left
                  v-text="data.item.slice(0, 1).toUpperCase()"
                >
                </v-avatar>
                {{ data.item }}
              </v-chip>
            </template>
          </v-combobox>

          <v-switch
            prepend-icon="mdi-account-star"
            v-model="cliente"
            label="Anexar información exclusiva para el cliente"
            @change="check($event)"
            outlined
          >
          </v-switch>

          <v-text-field
            background-color="rgba(0, 255, 17, 0.05)"
            prepend-icon="mdi-format-text"
            v-model="titulo_minuta2"
            label="Título: ingrese un título para el cliente"
            v-show="isCliente"
            outlined
          ></v-text-field>
          <!-- <v-textarea
            background-color="rgba(0, 255, 17, 0.05)"
            prepend-icon="mdi-clipboard-text-outline"
            v-model="minuta2"
            label="Descripción: ingrese una descripción exclusiva para el cliente"
            v-show="isCliente"
            outlined
          >
          </v-textarea> -->

          <v-switch
            prepend-icon="mdi-image-multiple"
            v-model="files"
            label="Incluir fotos"
            @click="toggleFiles"
          ></v-switch>

          <div id="app1" v-if="isFilesOpen" class="web-camera-container">
            <v-container fluid style="width: 600px">
              <upload-image
                :idRegistro="idRegistro"
                v-if="renderComponent"
              ></upload-image>
            </v-container>

            <v-switch
              prepend-icon="mdi-camera"
              v-model="fotos"
              label="Tomar fotografía"
              @click="toggleCamera"
            >
            </v-switch>

            <div id="app2" v-if="isCameraOpen" class="web-camera-container">
              <div v-show="isCameraOpen && isLoading" class="camera-loading">
                <ul class="loader-circle">
                  <li></li>
                  <li></li>
                  <li></li>
                </ul>
              </div>

              <div
                v-if="isCameraOpen"
                v-show="!isLoading"
                class="camera-box"
                :class="{ flash: isShotPhoto }"
              >
                <div
                  class="camera-shutter"
                  :class="{ flash: isShotPhoto }"
                ></div>

                <video
                  v-show="!isPhotoTaken"
                  ref="camera"
                  width="320"
                  height="240"
                  autoplay
                ></video>

                <canvas
                  v-show="isPhotoTaken"
                  id="photoTaken"
                  ref="canvas"
                  width="320"
                  height="240"
                ></canvas>

                <canvas
                  v-show="false"
                  id="photoTaken2"
                  ref="canvas2"
                  width="1280"
                  height="960"
                ></canvas>
              </div>

              <div v-if="isCameraOpen && !isLoading" class="camera-shoot">
                <button type="button" class="button" @click="takePhoto">
                  <img
                    src="https://img.icons8.com/material-outlined/50/000000/camera--v2.png"
                  />
                </button>
              </div>

              <div v-if="isPhotoTaken && isCameraOpen" class="camera-download">
                <a
                  id="downloadPhoto"
                  download="my-photo.jpg"
                  class="button"
                  role="button"
                  @click="downloadImage"
                >
                  Guardar
                </a>
              </div>
            </div>
          </div>

          <v-row style="padding: 3em" justify="center">
            <v-dialog v-model="dialog" persistent max-width="290">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="!valid"
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  Enviar Informe
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="text-h5"> Confirmación </v-card-title>
                <v-card-text
                  >Está seguro de haber ingresado correctamente toda la
                  información?.
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="green darken-1" text @click="dialog = false">
                    No
                  </v-btn>
                  <v-btn color="green darken-1" text @click="validate">
                    Si
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UploadImage from "../components/UploadImage";

const axios = require("axios");
export default {
  components: {
    UploadImage,
  },

  data: () => ({
    lugares: null,

    items: [],
    consignas: [],
    cordenadas: "",
  
    isCameraOpen: false,
    isFilesOpen: false,
    isPhotoTaken: false,
    isShotPhoto: false,
    isLoading: false,
    link: "#",

    idRegistro: "0",

    renderComponent: true,

    dialog: false,

    valid: true,

    fecha: "",
    fechaSis: "",
    titulo_minuta1: "",
    titulo_minuta2: "",
    minuta1: "",
    minuta2: "",
    isCliente: false,
    isUbicacion: false,
    isUbicacion_text: "",
    isConsignas: false,
    ubicacion: "",

    espera: false,

    cliente: "",
    fotos: "",
    files: "",

    select1: [],

    areas: [],
    value: ["", "", "", "", "", "", "", "", "", ""],

    tituloRules: [
      (v) => !!v || "El título es requerido",
      //(v) => (v && v.length >= 10) || "Eltítulo debe contener más de 10 caracteres",
    ],
    descripcionRules: [
      (v) => !!v || "La descripción es requerida",
      //(v) => (v && v.length >= 10) || "La descripción debe contener más de 10 caracteres",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      //(v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    select: null,

    checkbox: false,
  }),

  mounted() {
    this.$watchLocation({
      enableHighAccuracy: true,
    }).then((coordinates) => {
      console.log(coordinates);
      this.cordenadas = coordinates;
    });

    axios
      .post(this.$localhost + "/lista-areas.php", {
        request: 1,
        empresa: localStorage.getItem("empresa"),
      })
      .then((response) => {
        console.log(response.data);
        this.areas = response.data;
        //console.log(response);
      })
      .catch((error) => {
        this.response = "Error: " + error.response.status;
      });

   

    axios
      .post(this.$localhost + "/trae-clientes.php", {
        request: 1,
        empresa: localStorage.getItem("empresa"),
      })
      .then((response) => {
        // console.log(response);
        this.items = response.data;
        //console.log(response);

        for (let i = 0; i < this.items.length; i++) {
          if (
            this.cordenadas.lng >= Number(this.items[i].longitud) - 0.005 &&
            this.cordenadas.lng <= Number(this.items[i].longitud) + 0.005
          ) {
            this.lugares = this.items[i].id;
            this.ubicacion = this.items[i].id;
            console.log(this.items[i].id);
            this.traeConsignas(this.ubicacion)
          }
        }
      })
      .catch((error) => {
        this.response = "Error: " + error.response.status;
      });

    this.tomaFecha();

    if (this.isUbicacion == true) {
      this.isUbicacion_text = "1";
    }

    if (this.isUbicacion == false) {
      this.isUbicacion_text = "0";
    }

    if (this.idRegistro == "0") {
      axios
        .post(this.$localhost + "/genera-registro.php", {
          usuario: localStorage.getItem("usuario"),
          fuera_ubicacion: this.isUbicacion_text,
          fecha: this.fechaSis,
        })
        .then((response) => {
          // console.log(response);
          this.response = response.data;
          this.success = "Data saved successfully";
          //this.response = JSON.stringify(response, null, 2);
          this.idRegistro = this.response;
          //console.log(this.idRegistro + "este");
        })
        .catch((error) => {
          this.response = "Error: " + error.response.status;
        });
    }
  },

  methods: {

     traeConsignas: function(ubicacion){
    axios
      .post(this.$localhost + "/trae-consignas.php", {
        request: 1,
        empresa: localStorage.getItem("empresa"),
        objetivo: ubicacion
      })
      .then((response) => {
        console.log(response.data);
        this.consignas = response.data;
        if(this.consignas==""){
          this.isConsignas = false;
        }else{
          this.isConsignas = true;
        }
        //console.log(response);
        
      })
      .catch((error) => {
        this.response = "Error: " + error.response.status;
      });
    },

    check: function (e) {
      //console.log(e);
      if (e === false || e.length === true) {
        // Custom checks in this
        //console.log("Unchecked");
        this.isCliente = false;
      } else {
        //console.log("Checked");
        this.isCliente = true;
      }
    },

    chequeaUbicacion: function (e) {
      //console.log(e);
      if (e === false || e.length === true) {
        // Custom checks in this
        //console.log(e);
        this.isUbicacion = false;
      } else {
        console.log(this.ubicacion);
        this.traeConsignas(this.lugares)
        if (this.ubicacion == e) {
          this.isUbicacion = false;
        } else {
          this.isUbicacion = true;
          this.traeConsignas(this.lugares)
        }
        //console.log("Checked");
      }
    },

    restHours(h) {
      this.setHours(this.getHours() - h);
      return this;
    },

    tomaFecha() {
      var d = new Date();
      const datetime_sis = new Date(d.setHours(d.getHours() - 3))
        .toJSON()
        .slice(0, 19)
        .replace("T", " ");
      var datetime = new Date().toLocaleString();

      this.fecha = datetime;
      this.fechaSis = datetime_sis;
    },

    forceRerender() {
      // Removing my-component from the DOM
      this.renderComponent = false;
      this.$nextTick(() => {
        // Adding the component back in
        this.renderComponent = true;
      });
    },

    validate() {
      if (this.$refs.form.validate()) {
        //alert("hola");
        this.dialog = false;
        this.submitForm();
      } else {
        //alert("chau");
      }
    },

    submitForm() {
      if (this.isUbicacion == true) {
        this.isUbicacion_text = "1";
      }

      if (this.isUbicacion == false) {
        this.isUbicacion_text = "0";
      }

      axios
        .post(this.$localhost + "/guarda-registro.php", {
          idRegistro: this.idRegistro,
          objetivo: this.lugares,
          fecha: this.fechaSis,
          cliente: this.cliente,
          titulo_minuta1: this.titulo_minuta1,
          titulo_minuta2: this.titulo_minuta2,
          minuta1: this.minuta1,
          minuta2: this.minuta2,
          select_area: this.select1,
          fuera_ubicacion: this.isUbicacion_text,
          cordenada_lat: this.cordenadas.lat,
          cordenada_lon: this.cordenadas.lng,
        })
        .then((response) => {
          // console.log(response);
          // this.response = response.data
          this.success = "Data saved successfully";
          this.response = JSON.stringify(response, null, 2);
          //console.log(this.response);
        })
        .catch((error) => {
          this.response = "Error: " + error.response.status;
        });
      /*this.objetivo = "",
                this.fecha = "",
                this.titulo_minuta1 = "",
                this.titulo_minuta2 = "",
                this.minuta1 = "",
                this.minuta2 = ""
            */
      if (this.isCameraOpen) {
        this.isCameraOpen = false;
        this.isPhotoTaken = false;
        this.isShotPhoto = false;
        this.stopCameraStream();
      }
      this.$router.push({ path: "/correcto" });
    },

    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },

    toggleCamera() {
      if (this.isCameraOpen) {
        this.isCameraOpen = false;
        this.isPhotoTaken = false;
        this.isShotPhoto = false;
        this.stopCameraStream();
      } else {
        this.isCameraOpen = true;
        this.createCameraElement();
      }
    },

    toggleFiles() {
      if (this.isFilesOpen) {
        this.isFilesOpen = false;
      } else {
        this.isFilesOpen = true;
      }
    },

    createCameraElement() {
      //console.log(navigator.mediaDevices.getSupportedConstraints());
      this.isLoading = true;

      const videoConstraints = {
        facingMode: "environment",
      };
      const constraints = (window.constraints = {
        audio: false,
        video: videoConstraints /*{
                    minAspectRatio: 1.333,
                    minFrameRate: 30,
                    
                        /*facingMode: {
                            exact: 'environment'
                        }
                },*/,
      });

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          this.isLoading = false;
          this.$refs.camera.srcObject = stream;
        })

        .catch((error) => {
          this.isLoading = false;
          console.error(error);
          //alert("Esta función puede no funcionar en este dispositivo.");
        });
    },

    stopCameraStream() {
      let tracks = this.$refs.camera.srcObject.getTracks();

      tracks.forEach((track) => {
        track.stop();
      });
    },

    takePhoto() {
      if (!this.isPhotoTaken) {
        this.isShotPhoto = true;
        //console.log("saca");
        const FLASH_TIMEOUT = 50;

        setTimeout(() => {
          this.isShotPhoto = false;
        }, FLASH_TIMEOUT);
      }

      this.isPhotoTaken = !this.isPhotoTaken;

      const context = this.$refs.canvas.getContext("2d");
      context.drawImage(this.$refs.camera, 0, 0, 320, 240);

      const context2 = this.$refs.canvas2.getContext("2d");
      context2.drawImage(this.$refs.camera, 0, 0, 1280, 960);
      //alert("La resolución de tu pantalla es: " + screen.width + " x " + screen.height);
    },

    downloadImage() {
      const formData = new FormData();

      //const download = document.getElementById("downloadPhoto");
      const canvas = document
        .getElementById("photoTaken2")
        .toDataURL("image/jpeg");
      //.replace("image/jpeg", "image/octet-stream");
      //download.setAttribute("href", canvas);

      formData.append("archivo", canvas);
      formData.append("id_registro", this.idRegistro);
      axios.post(this.$localhost + "/sube-imagenes-camara.php", formData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      this.isPhotoTaken = !this.isPhotoTaken;

      this.forceRerender();
    },
  },
};
</script>

<style lang="scss">
video {
  width: 100%;
}

.web-camera-container {
  //margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;

  .camera-button {
    margin-bottom: 2rem;
  }

  .camera-box {
    .camera-shutter {
      opacity: 0;

      background-color: #fff;
      position: absolute;

      &.flash {
        opacity: 1;
      }
    }
  }

  .camera-shoot {
    margin: 1rem 0;

    button {
      height: 60px;
      width: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;

      img {
        height: 35px;
        object-fit: cover;
      }
    }
  }

  .camera-loading {
    overflow: hidden;
    height: 100%;
    position: absolute;
    width: 100%;
    min-height: 150px;
    margin: 3rem 0 0 -1.2rem;

    ul {
      height: 100%;
      position: absolute;
      width: 100%;
      z-index: 999999;
      margin: 0;
    }

    .loader-circle {
      display: block;
      height: 14px;
      margin: 0 auto;
      top: 50%;
      left: 95%;
      transform: translateY(-50%);
      transform: translateX(-50%);
      position: absolute;
      width: 100%;
      padding: 0;

      li {
        display: block;
        float: left;
        width: 10px;
        height: 10px;
        line-height: 10px;
        padding: 0;
        position: relative;
        margin: 0 0 0 4px;
        background: #999;
        animation: preload 1s infinite;
        top: -50%;
        border-radius: 100%;

        &:nth-child(2) {
          animation-delay: 0.2s;
        }

        &:nth-child(3) {
          animation-delay: 0.4s;
        }
      }
    }
  }

  @keyframes preload {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.4;
    }

    100% {
      opacity: 1;
    }
  }
}
</style>
