<template>

  <Correcto />


  </template>

<script>

  import Correcto from '../components/Correcto'


  export default {
    name: 'RespuestaCorrecto',

    components: {
      Correcto,
  
    },
  }
</script>