import http from "../http-common";

class UploadFilesService {
    upload(file, onUploadProgress, id) {
        let formData = new FormData();

        formData.append("file", file);
        formData.append("id", id);

        return http.post("/sube-imagenes-galeria.php", formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            },

            onUploadProgress
        });
    }

    getFiles(id) {

        return http.post("/lista-imagenes.php", { request: 1, id: id });
    }
}

export default new UploadFilesService();